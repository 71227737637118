.MuiDropzoneArea-root {
  min-height: 160px !important;
}

.ERROR {
  background-color: #ff000057 !important;
}

.READY {
  background-color: #00800061 !important;
}

.MuiList-root .MuiListItemIcon-root {
  min-width: 40px;
}

.MuiDataGrid-row.DECLINED {
  background: #e573735e !important;
}

.MuiDataGrid-row.CONFIRMED {
  background: #81c7847a !important;
}

div#dropzone {
  border: 2px dashed #0087f7;
  border-radius: 5px;
  background: white;
  padding: 30px 30px;
  margin-top: 20px;
  text-align: center;
}

.attachments .attachment:last-child {
  border-bottom: none;
}

.attachments {
  padding: 8px 16px !important;
}
.MuiFormHelperText-root {
  font-size: 0.85rem !important;
}

.szh-menu-button {
  color: #555;
}

.org-switcher-button {
  color: #fff;
}

.app-switcher .szh-menu {
  position: fixed !important;
  left: 6px !important;
  top: 56px !important;
}

.app-switcher .notification {
  background: #ff1a1a;
}

.app-switcher .szh-menu__item {
  padding: 4px 8px !important;
}
